<i18n locale="th" lang="yaml" >
manufacture.action.auto-fill.label : "ใส่ข้อมูล"
manufacture.action.clear.label : "ล้างข้อมูล"

manufacture.model.title : "ผลิตสำหรับ Drone รุ่น {model}"
manufacture.model.empty : "ไม่มีพบการผลิต Drone Model นี้แล้ว"

action.save.label : "บันทึก"
action.save.confirm.title : "ยืนยันการบันทึกค่าการผลิต Drone"
action.save.confirm.message : "คุณต้องการบันทึกเปลี่ยนแปลงค่าของการผลิต Drone นี้ ?"
action.save.confirm.success : "ทำการบันทึกการผลิตของ Drone สำหรับ {manufacturer} เรียบร้อย"

action.create.label : "สร้าง Drone ตามรายการ"
action.create.confirm.title : "ยืนยันการสร้าง Drone"
action.create.confirm.message : "คุณต้องการสร้าง Drone จากการผลิตของ {manufacturer} นี้ ?"
action.create.confirm.success : "ทำการสร้าง Drone เรียบร้อย"

action.create.error.message.field.lot: "กรุณาเลือก Lot"
action.create.error.message.field.firmware: "กรุณาเลือก Firmware"
action.create.error.message.field.name: "กรุณากรอกชื่อโดรน"

button.create_lot : "สร้าง Lot ใหม่"

</i18n>

<template>
	<div class="manufacture-pane">
		<div class="header">
			<span class="title">
				{{$t('manufacture.model.title',{ model: droneModel.name })}}
			</span>
			<a-button
					v-if="canCreateLot"
					class="btn-page-action"
					type="primary"
					icon="plus"
					@click="handleClickOpenLotCreateModal">
					{{$t('button.create_lot')}}
			</a-button>
		</div>

		<ErrorMessagePane :error="error"/>

		<ManufactureTable
			v-if="$notEmpty(dataList)"
			:manufactures="dataList"
			:can-update="canUpdate"
			:drone-model="droneModel"
			:delete-list="deleteList"
			:model-error="modelError"
			:model-warning="modelWarning"
			@delete="handleDeleteManufacture"
			@undo-delete="handleUndoDeleteManufacture"/>
		<div v-else class="empty">
			{{$t('manufacture.model.empty')}}
		</div>

		<div v-if="canUpdate && $notEmpty(dataList)" class="manufacture-actions">
			<a-button type="primary" @click="handleCreate">
				<a-icon type="plus" />
				{{$t('action.create.label')}}
			</a-button>
		</div>
		<LotCreateModal
			ref="lotCreateModalRef"
			:drone-model="droneModel"/>
	</div>
</template>

<script>
import ManufactureTable from "@components/manufacture/ManufactureTable.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import LotCreateModal from "@components/lot/LotCreateModal.vue"
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
export default {
	components : {
		ErrorMessagePane,
		ManufactureTable ,LotCreateModal,
	} ,
	mixins : [PageMixin] ,
	props : {
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		droneModel : {
			type : null,
			default : () => []
		} ,
		manufacturer : {
			type : String,
			default : undefined,
		} ,
		manufactures : {
			type : null,
			default : () => []
		} ,
		initialWarnings : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			oldList : [] ,

			deleteList : [] ,
			dataList : [],
			currentAutoFills : {
				lotName : undefined,
				startNo : undefined,
			},
			error : undefined,
			modelError : undefined,
			modelWarning : undefined,
		}
	} ,
	computed : {
		canCreateLot() {
			return this.droneModel.id && this.$authorize('create','droneLot');
		},
	} ,
	watch : {
		manufactures : {
			deep: true,
			handler() {
				this.initial(this.manufactures,true)
			}
		}
	} ,
	mounted() {
		this.initial(this.manufactures,true)
	} ,
	methods : {
		initial(manufactures,firstTime=false) {
			this.dataList = []
			this.oldList = []
			this.error = undefined
			if (firstTime)
				this.modelWarning = this.initialWarnings
			else
				this.modelWarning = this.initialWarnings

			this.modelError = undefined
			manufactures.forEach((m)=> {
				this.dataList.push({...m})
				this.oldList.push({...m})
			})
		} ,
		handleDeleteManufacture(id) {
			if (this.deleteList.indexOf(id) == -1) {
				this.deleteList.push(id)
			}
		} ,
		handleUndoDeleteManufacture(id) {
			if (this.deleteList.indexOf(id) >= 0) {
				this.deleteList.splice(this.deleteList.indexOf(id),1)
			}
		} ,
		handleCreate() {
			if (!this.canUpdate)
				return
			this.$confirm({
				title : this.$t('action.create.confirm.title',{manufacturer : this.manufacturer}) ,
				content : this.$t('action.create.confirm.message',{manufacturer : this.manufacturer}) ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					const formData = {
						manufactures : this.dataList,
					}
					const isError = this.validateFieldsError(formData.manufactures)
					if(isError) return

					this.showPageLoading(true)
					axios.post("/api/manufacture/create",formData).then((response) => {
						this.$message.success(this.$t('action.create.confirm.success',{'manufacturer' : this.manufacturer}))
						this.$router.go()
					}).catch((err)=>{
						this.error = err
					}).finally(() => {
						this.hidePageLoading()
					})
				}
			})
		},
		handleClickOpenLotCreateModal() {
			this.$refs.lotCreateModalRef.handleClickOpenModal()
		},
		handleCreatedNewLot(newLot){
			this.$refs.lotCreateModalRef.handleClickCloseModal()
		},
		validateFieldsError(manufactures){
			for (const manufacture of manufactures) {
				if (!manufacture.lotId) {
					this.$message.error(this.$t('action.create.error.message.field.lot'))
					return true
				} else if (!manufacture.firmwareId) {
					this.$message.error(this.$t('action.create.error.message.field.firmware'))
					return true
				} else if (!manufacture.name) {
					this.$message.error(this.$t('action.create.error.message.field.name'))
					return true
				}
			}
			return false
		},
	}
}
</script>

<style lang="less" scoped>
.manufacture-pane {
	margin-bottom : 32px;
	.header {
		margin-bottom : 16px;
		.title {
			font-family: @font-family-title;
			color : @secondary-color;
			text-decoration: underline;
		}
		.actions {
			display : inline-block;
			margin-left : 24px;
			> button {
				font-size : 0.95em;
				min-width : 85px;
			}
		}
	}
}
.manufacture-actions {
	margin-top : 12px;
}
.btn-page-action{
	margin-inline: 28px;
}
</style>
