<i18n locale="th" lang="yaml" >
drone_manufacture.field.model : "รุ่น"
drone_manufacture.field.serial_no : "S/N"
drone_manufacture.field.pixhawk_serial_no : "Pixhawk S/N"
drone_manufacture.field.manufacture_date : "วันผลิต"
drone_manufacture.field.detail : "รายละเอียด"
drone_manufacture.field.drone_name : "ชื่อ Drone"
drone_manufacture.field.lot_name : "Lot"
drone_manufacture.field.remote : "Remote Controller"
drone_manufacture.field.firmware : "Firmware"
drone_manufacture.field.created_datetime : "วันที่ใส่ข้อมูล"

drone_manufacture.field.remote_sku : "SKU"
drone_manufacture.field.remote_serial_no : "S/N"
</i18n>

<template>
	<a-table
		bordered class="mytable drone-manufacture-table mytable-strip"
		:row-key="record => record.id"
		:data-source="manufactures"
		:pagination="false"
		size="small">
		<a-table-column
			:title="$t('drone_manufacture.field.serial_no')"
			data-index="droneSerialNo"
			:width="120">
			<template slot-scope="text,record">
				<span>
					<DataWithFeedback
						:content="record.droneSerialNo"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.pixhawk_serial_no')"
			data-index="flightControllerSerialNo"
			:width="150">
			<template slot-scope="text,record">
				<span>
					<DataWithFeedback
						:content="record.flightControllerSerialNo"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.manufacture_date')"
			data-index="manufactureDate"
			:width="115">
			<template slot-scope="text">
				{{text | date}}
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.detail')"
			:width="120">
			<template slot-scope="record">
				<div v-if="canUpdate">
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.lot_name')}}</label><br/>
						<a-select
							v-model="record.lotId"
							style="width:100%"
							:placeholder="$t('drone_manufacture.field.lot_name')">
							<a-select-option v-for="lot of lots" :key="lot.id"
								:value="lot.id">
								{{lot.lotName}}
							</a-select-option>
						</a-select>
					</div>
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.drone_name')}}</label>
						<InputWithFeedback
							v-model="record.name"
							:error="getError(record.id,'drone_name')"
							:warning="getWarning(record.id,'drone_name')"
							allow-clear/>
					</div>
				</div>
				<div v-else>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.lot_name')}}</label>
						<span class="input-value">{{emptyCheck}}</span>
					</div>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.drone_name')}}</label>
						<span class="input-value">{{record.name | emptyCheck}}</span>
					</div>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.remote')"
			:width="150">
			<template slot-scope="record">
				<div>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.remote_sku')}}</label>
						<span class="input-value">{{record.remoteSku | emptyCheck}}</span>
					</div>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.remote_serial_no')}}</label>
						<span class="input-value">{{record.remoteSerialNo | emptyCheck}}</span>
					</div>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.firmware')"
			:width="150">
			<template slot-scope="record">
				<div class="input-block">
					<label>{{$t('drone_manufacture.field.firmware')}}</label><br/>
					<a-select
						v-model="record.firmwareId"
						style="width:100%"
						:placeholder="$t('drone_manufacture.field.firmware')">
						<a-select-opt-group v-for="group of record.firmwares" :key="group.title">
							<span slot="label">
								{{group.title}}
							</span>
							<a-select-option v-for="item in group" :key="item.id" :value="item.id">
								{{item.name}}
							</a-select-option>
						</a-select-opt-group>
					</a-select>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.created_datetime')"
			:width="115">
			<template slot-scope="text,record">
				{{record.createdAt | date}}
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table,Select} from "ant-design-vue"
import InputWithFeedback from "@components/input/InputWithFeedback.vue"
import DataWithFeedback from "@components/common/DataWithFeedback.vue"
import ModelError from "@utils/errors/ModelError"
import { mapGetters } from 'vuex'
export default {
	components : {
		"a-table" : Table ,"a-table-column" : Table.Column ,
		InputWithFeedback, DataWithFeedback,
		"a-select" : Select , "a-select-option" : Select.Option,
	} ,
	props : {
		manufactures : {
			type : null,
			default : () => []
		} ,
		droneModel : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		modelError : {
			type : null,
			default : () => []
		} ,
		modelWarning : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			hasChangeLot : false,
			currentLotName : '' ,
		}
	} ,
	computed: {
		...mapGetters('lot',['getLotsByDroneModelId']),
		lots(){
			return this.getLotsByDroneModelId(this.droneModel.id)
		},
  },
	methods : {
		getError(id,field) {
			if (this.modelError instanceof ModelError) {
				const error = this.modelError.getFieldErrorsByModelKey(id)
				if (error)  {
					if (field in error.field_errors) {
						return error.field_errors[field].join(', ')
					}
				}
			}
			return undefined
		} ,
		getWarning(id,field) {
			if (this.modelWarning instanceof ModelError) {
				const error = this.modelWarning.getFieldErrorsByModelKey(id)
				if (error)  {
					if (field in error.field_errors) {
						return error.field_errors[field].join(', ')
					}
				}
			}
			return undefined
		} ,
	}
}
</script>

<style lang="less" scoped>
.input-block {
	margin-bottom :  8px;
	label  {
		color : @info-color;
	}

	&:last-child {
		margin-bottom : 0;
	}

	&.view {
		label::after {
			content :" : "
		}
	}
}
.drone-manufacture-table {
	min-width : 1024px;
}
</style>
