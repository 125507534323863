<i18n locale="th" lang="yaml" >
	lot.page.title: "{name} :: เพิ่ม Lot ใหม่"
	lot.page.header: "เพิ่ม Lot ใหม่สำหรับ Model {name}"
	lot.page.description : "เพิ่ม Lot ใหม่ ที่ใช้งาน Drone Model รุ่น {name}"
	lot.submit.label : "เพิ่ม Lot ใหม่"
	lot.confirm.title : "ยืนยันการสร้าง Drone Lot ใหม่"
	lot.confirm.message : "คุณต้องการสร้าง Drone Lot ใหม่ สำหรับ รุ่นนี้ ?"
	lot.create.success : "ทำการเพิ่ม Lot ใหม่ {name} สำหรับ Drone Model {modelName} เรียบร้อย"
	lot.action.cancel.label : "ยกเลิก"
</i18n>

<template>
	<a-modal v-model="visible" class="contain">
		<template slot="footer">
			<a-button key="back" @click="handleClickCloseModal">
				{{ $t('lot.action.cancel.label') }}
			</a-button>
		</template>
		<my-page-header
			:title="$t('lot.page.header',{ name: droneModel.name })"
			:description="$t('lot.page.description',{ name: droneModel.name })" />
		<DroneLotForm
			ref="formRef"
			:loading="loading"
			mode="create"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('lot.submit.label')}}
				</a-button>
			</template>
		</DroneLotForm>
	</a-modal>
</template>

<script>
import axios from "axios"
import DroneLotForm from "@components/drone/DroneLotForm.vue"
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		DroneLotForm,
	},
	props: {
		droneModel: {
			type: null,
			default: () => [],
		},
	},
	data() {
		return {
			loading : false,
			visible: false
		}
	},
	computed: {
		...mapState('lot', ['lots']),
  },
	methods:{
		...mapActions('lot',['updateLots', 'fetchLots']),
		handleClickOpenModal() {
			this.visible = true
		},
		handleClickCloseModal() {
			this.visible = false
		},
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('lot.confirm.title') ,
				content : this.$t('lot.confirm.message') ,
				maskClosable : true,
				onOk: async () => {
					this.loading = true
					const sendFormData = {...formData,droneModelId: this.droneModel.id}
					try {
						const response = await axios.post("/api/drone-lots/create",sendFormData)
						const newLot = response.data.data.lot
						await this.fetchLots()
						this.$message.success(this.$t('lot.create.success',{"name" : newLot.lotName,"modelName" : this.droneModel.name}))
						this.visible = false
					} catch (error) {
						this.$refs.formRef.formErrors(error)
					} finally {
						this.loading = false;
					}
				}
			});
		}
	}
}
</script>

<style lang="less">
.ant-modal-content{
	width: 700px
}
</style>
