<i18n locale="th" lang="yaml" >
page.title : "รายการ Drone ที่อยู่ระหว่างผลิต"
page.description : "รายการของ Drone ที่ถูกผลิต โดยรับข้อมูลมาจาก HGDKit ซึ่งนำข้อมูลมาจากโรงงาน โดยผู้ใช้งานจะต้องทำการระบุ Lot และทำการ Approve เพื่อนำข้อมูลเหล่านี้เพิ่มเป็นฐานข้อมูลของ Drone ใหม่"

manufacture.manufacturer.title : "ข้อมูลการผลิตจาก โรงงาน {code}"
manfacture.empty : "ไม่มีข้อมูลการผลิต Drone ชุดใหม่"
</i18n>

<template>
	<div v-if="!loading" class="page page-padding">
		<my-page-header :title="$t('page.title')"
			:description="$t('page.description')"/>
		<div class="manufacture-container">
			<div v-if="$notEmpty(manufactures)" class="manufacture-parent">
				<ManufacturePane v-for="(droneManufactures ,droneModelId) in manufactures" :key="droneModelId"
						:can-update="canUpdate"
						:drone-model="droneModels[droneModelId]"
						:initial-warnings="initialWarnings"
						:manufactures="droneManufactures"/>
			</div>
			<div v-else class="manufacture-container empty">
				{{$t('manfacture.empty')}}
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import ManufacturePane from "@components/manufacture/ManufacturePane.vue"
import { mapActions } from 'vuex'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
		ManufacturePane ,
	} ,
	mixins : [PageMixin] ,
	data() {
		return {
			manufactures: {},
			droneModels : {} ,
			initialWarnings : [] ,

			loading : false,
		}
	} ,
	computed : {
		canUpdate() {
			return this.$authorize('update', 'manufacture')
		} ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		...mapActions('lot',['fetchLots']),
		async fetchData() {
			this.showPageLoading()
			this.loading = true
			try {
				const response = await axios.get("/api/manufacture/list")
				this.droneModels = response.data.data.droneModels
				for (const manufacture of response.data.data.manufactures) {
					if(this.manufactures[manufacture.droneModelId]?.length >= 0){
						this.manufactures[manufacture.droneModelId].push(manufacture);
					}
					else{
						this.manufactures[manufacture.droneModelId] = [{...manufacture}];
					}
				}
				await this.fetchLots()
			} catch (error) {
				this.fetchError(error)
			} finally{
				this.loading = false
				this.hidePageLoading()
			}
		} ,
	}
}
</script>

<style lang="less" scoped>
.manufacture-container {
	background : @white;
	padding : 16px 32px;
	margin-bottom : 24px;

	.title {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-bottom : 16px;
		color : @primary-color;
	}

	&.empty {
		color : @error-color;
		font-size : 1.2em;
		font-family: @font-family-title;
		text-align : center;
		padding-top : 48px;
		padding-bottom : 48px;
	}
}
</style>
